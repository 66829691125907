import React from "react";
import MenuChoiceDisplay from "../../Components/MenuPageComponent/MenuBanner/MenuChoice/MenuChoiceDisplay";
import MenuWeeklyContent from "../../Components/MenuPageComponent/MenuPages/MenuPagesList/MenuWeeklyContent";



const MenuPageScreen = () => {
  
  return (
    <>
      <MenuChoiceDisplay />
      {/* <MenuWeeklyContent /> */}
    </>
  );
};
export default MenuPageScreen;
