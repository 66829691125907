export const DELIVERY_DISPATCH_REQUEST = 'DELIVERY_DISPATCH_REQUEST';
export const DELIVERY_DISPATCH_SUCCESS = 'DELIVERY_DISPATCH_SUCCESS';
export const DELIVERY_DISPATCH_FAIL = 'DELIVERY_DISPATCH_FAIL';

export const DELIVERY_CREATE_REQUEST = 'DELIVERY_CREATE_REQUEST';
export const DELIVERY_CREATE_SUCCESS = 'DELIVERY_CREATE_SUCCESS';
export const DELIVERY_CREATE_FAIL = 'DELIVERY_CREATE_FAIL';

export const DELIVERY_LIST_REQUEST = 'DELIVERY_LIST_REQUEST';
export const DELIVERY_LIST_SUCCESS = 'DELIVERY_LIST_SUCCESS';
export const DELIVERY_LIST_FAIL = 'DELIVERY_LIST_FAIL';

export const DELIVERY_DETAILS_REQUEST = 'DELIVERY_DETAILS_REQUEST';
export const DELIVERY_DETAILS_SUCCESS = 'DELIVERY_DETAILS_SUCCESS';
export const DELIVERY_DETAILS_FAIL = 'DELIVERY_DETAILS_FAIL';

export const DELIVERY_UPDATE_REQUEST = 'DELIVERY_UPDATE_REQUEST';
export const DELIVERY_UPDATE_SUCCESS = 'DELIVERY_UPDATE_SUCCESS';
export const DELIVERY_UPDATE_FAIL = 'DELIVERY_UPDATE_FAIL';

export const DELIVERY_MISS_REQUEST = 'DELIVERY_MISS_REQUEST';
export const DELIVERY_MISS_SUCCESS = 'DELIVERY_MISS_SUCCESS';
export const DELIVERY_MISS_FAIL = 'DELIVERY_MISS_FAIL';

export const DELIVERY_DELETE_REQUEST = 'DELIVERY_DELETE_REQUEST';
export const DELIVERY_DELETE_SUCCESS = 'DELIVERY_DELETE_SUCCESS';
export const DELIVERY_DELETE_FAIL = 'DELIVERY_DELETE_FAIL';