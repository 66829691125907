import React from 'react';
import recipeImg from './recipeImg';

const recipeHeading =(props)=>{
    return (
        <p>{props.name}</p>
    )
}
export default recipeHeading;

// Greek-Style Veggie Pitas