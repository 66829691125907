const {
  ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_DETAILS_FAIL,
  MY_ORDER_LIST_REQUEST, MY_ORDER_LIST_SUCCESS, MY_ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_FAIL,
  ORDER_PAY_REQUEST, ORDER_PAY_SUCCESS, ORDER_PAY_FAIL,
  ORDER_DELIVER_REQUEST, ORDER_DELIVER_SUCCESS, ORDER_DELIVER_FAIL,
  ORDER_DELETE_REQUEST, ORDER_DELETE_SUCCESS, ORDER_DELETE_FAIL,
  ORDER_ANONYMOUS_REQUEST, ORDER_ANONYMOUS_SUCCESS, ORDER_ANONYMOUS_FAIL,
  ORDER_EMAIL_REQUEST, ORDER_EMAIL_SUCCESS, ORDER_EMAIL_FAIL,
  ORDER_REMARK_REQUEST, ORDER_REMARK_SUCCESS, ORDER_REMARK_FAIL,
  ORDER_COURIER_LIST_REQUEST, ORDER_COURIER_LIST_SUCCESS, ORDER_COURIER_LIST_FAIL,
} = require("../Constants/orderConstant");

const { USER_LOGOUT } = require("../Constants/userConstants");
const { CART_SAVE_SHIPPING, CART_SAVE_ANONYMOUS, } = require("../Constants/cartConstant");

function orderCreateReducer(state = {}, action) {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, order: action.payload, success: true };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    case CART_SAVE_SHIPPING:
      return {};
    case ORDER_EMAIL_SUCCESS:
      return {};
    default:
      return state;
  }
}

function OrderDetailsReducer(
  state = {
    order: {
      orderItems: [],
      shipping: {},
      payment: {},
    },
  },
  action
) {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {
        order: {
          orderItems: [],
          shipping: {},
          payment: {},
        },
      };
    case CART_SAVE_SHIPPING:
      return {
        order: {
          orderItems: [],
          shipping: {},
          payment: {},
        },
      };
    default:
      return state;
  }
}

function myOrderListReducer(
  state = {
    order: [],
  },
  action
) {
  switch (action.type) {
    case MY_ORDER_LIST_REQUEST:
      return { loading: true };
    case MY_ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload };
    case MY_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {order: []};
    default:
      return state;
  }
}

function OrderListReducer(
  state = {
    order: [],
  },
  action
) {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true };
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload };
    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {order: []};
    default:
      return state;
  }
}

function orderPayReducer(
  state = {
    order: {
      orderItems: [],
      shipping: {},
      payment: {},
    },
  },
  action
) {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return { loading: true };
    case ORDER_PAY_SUCCESS:
      return { loading: false, success: true };
    case ORDER_PAY_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return { order: {
        orderItems: [],
        shipping: {},
        payment: {},
      },};
    case CART_SAVE_SHIPPING:
      return { order: {
        orderItems: [],
        shipping: {},
        payment: {},
      },};
    case ORDER_LIST_REQUEST:
      return {};
    case MY_ORDER_LIST_REQUEST:
      return {};
    case ORDER_EMAIL_SUCCESS:
      return {};
    default:
      return state;
  }
}

function orderDeliverReducer (state = {}, action) {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return { loading: true };
    case ORDER_DELIVER_SUCCESS:
      return { loading: false, success: true };
    case ORDER_DELIVER_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function orderDeleteReducer(
  state = {
    order: {
      orderItems: [],
      shipping: {},
      payment: {},
    },
  },
  action
) {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true };
    case ORDER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ORDER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {order: {
        orderItems: [],
        shipping: {},
        payment: {},
      },};
    default:
      return state;
  }
}

function orderAnonymousReducer(state = {}, action) {
  switch (action.type) {
    case ORDER_ANONYMOUS_REQUEST:
      return { loading: true };
    case ORDER_ANONYMOUS_SUCCESS:
      return { loading: false, order: action.payload, success: true };
    case ORDER_ANONYMOUS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    case CART_SAVE_SHIPPING:
      return {};
    case ORDER_EMAIL_SUCCESS:
      return {};
    default:
      return state;
  }
}

function orderEmailReducer(state = {}, action) {
  switch (action.type) {
    case ORDER_EMAIL_REQUEST:
      return { loading: true };
    case ORDER_EMAIL_SUCCESS:
      return { loading: false, success: true };
    case ORDER_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    case CART_SAVE_SHIPPING:
      return {};
    default:
      return state;
  }
}

function orderRemarkReducer (state = {}, action) {
  switch (action.type) {
    case ORDER_REMARK_REQUEST:
      return { loading: true };
    case ORDER_REMARK_SUCCESS:
      return { loading: false, success: true };
    case ORDER_REMARK_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

export {
  orderCreateReducer,
  OrderDetailsReducer,
  myOrderListReducer,
  OrderListReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderDeleteReducer,
  orderAnonymousReducer,
  orderEmailReducer,
  orderRemarkReducer,
};
