import React from 'react';
import Table from 'react-bootstrap/Table';

export const CustomersList = ({ customers }) => {
    
    const CustomerRow = (customer, index) => {
        return (
            <tr key={index} className='even'>
                <td>{index + 1}</td>
                <td>{customer.firstName}</td>
                <td>{customer.lastName}</td>
                <td>{customer.email}</td>
                <td>{customer.address}</td>
                <td>{customer.zipcode}</td>
            </tr>
        )
    }

    const CustomerTable = customers.map((cust,index)=> CustomerRow(cust,index))

    const tableHeader = <thead className='bgvi'>
        <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>Zipcode</th>
        </tr>
    </thead>

    return (
        <Table striped bordered hover>
            {tableHeader}
            <tbody>
                {CustomerTable}
            </tbody>
        </Table>
    )

}