export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_FACEBOOK_REQUEST = 'USER_FACEBOOK_REQUEST';
export const USER_FACEBOOK_SUCCESS = 'USER_FACEBOOK_SUCCESS';
export const USER_FACEBOOK_FAIL = 'USER_FACEBOOK_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_SMS_GET_REQUEST = 'USER_SMS_GET_REQUEST';
export const USER_SMS_GET_SUCCESS = 'USER_SMS_GET_SUCCESS';
export const USER_SMS_GET_FAIL = 'USER_SMS_GET_FAIL';
export const USER_SMS_VER_REQUEST = 'USER_SMS_VER_REQUEST';
export const USER_SMS_VER_SUCCESS = 'USER_SMS_VER_SUCCESS';
export const USER_SMS_VER_FAIL = 'USER_SMS_VER_FAIL';
export const USER_SMS_START_OVER = 'USER_SMS_START_OVER';

export const USER_FORGOT_REQUEST = 'USER_FORGOT_REQUEST';
export const USER_FORGOT_SUCCESS = 'USER_FORGOT_SUCCESS';
export const USER_FORGOT_FAIL = 'USER_FORGOT_FAIL';
export const USER_RESET_VALIDATE_REQUEST = 'USER_RESET_VALIDATE_REQUEST';
export const USER_RESET_VALIDATE_SUCCESS = 'USER_RESET_VALIDATE_SUCCESS';
export const USER_RESET_VALIDATE_FAIL = 'USER_RESET_VALIDATE_FAIL';
export const USER_RESET_REQUEST = 'USER_RESET_REQUEST';
export const USER_RESET_SUCCESS = 'USER_RESET_SUCCESS';
export const USER_RESET_FAIL = 'USER_RESET_FAIL';

export const USER_FULLLIST_REQUEST = 'USER_FULLLIST_REQUEST';
export const USER_FULLLIST_SUCCESS = 'USER_FULLLIST_SUCCESS';
export const USER_FULLLIST_FAIL = 'USER_FULLLIST_FAIL';

export const USER_FAV_LIST_REQUEST = 'USER_FAV_LIST_REQUEST';
export const USER_FAV_LIST_SUCCESS = 'USER_FAV_LIST_SUCCESS';
export const USER_FAV_LIST_FAIL = 'USER_FAV_LIST_FAIL';
export const USER_FAV_ADD_REQUEST = 'USER_FAV_ADD_REQUEST';
export const USER_FAV_ADD_SUCCESS = 'USER_FAV_ADD_SUCCESS';
export const USER_FAV_ADD_FAIL = 'USER_FAV_ADD_FAIL';
export const USER_FAV_DELETE_REQUEST = 'USER_FAV_DELETE_REQUEST';
export const USER_FAV_DELETE_SUCCESS = 'USER_FAV_DELETE_SUCCESS';
export const USER_FAV_DELETE_FAIL = 'USER_FAV_DELETE_FAIL';